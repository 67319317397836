import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { COMPANY_CENSUS } from '../../../../../../utility/SourceAPI'

export const getCensus = createAsyncThunk('appCensus/getCensus', async () => {
    const response = await axios.get(COMPANY_CENSUS)
    return {
        data: response.data
    }
})

export const addCensus = createAsyncThunk('appCensus/addCensus', async (census, { dispatch }) => {
    const response = await axios.post(COMPANY_CENSUS, census)
    await dispatch(getCensus())
    return {
        data: response.data
    }
})

export const updateCensus = createAsyncThunk('appCensus/updateCensus', async (census, { dispatch }) => {
    const response = await axios.put(COMPANY_CENSUS.concat(census.id), census)
    await dispatch(getCensus())
    return {
        data: response.data
    }
})


export const censusSlice = createSlice({
    name: 'appCensus',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCensus.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default censusSlice.reducer