// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"

import { EXPENSES } from "../../../../../../../utility/SourceAPI"
import {
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds,
    formattedDatePicker
} from "../../../../../../../utility/formats/formattedDate"

export const getExpenses = createAsyncThunk('appExpenses/getExpenses', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? EXPENSES.concat(`?intermediary_id=${data.id}`).concat(`&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : EXPENSES.concat(`?intermediary_id=${data.id}`))
    return {
        data: response.data
    }
})

export const newExpense = createAsyncThunk('appExpenses/addExpense', async (expense, { dispatch }) => {
    const response = await axios.post(EXPENSES, expense)
    await dispatch(getExpenses())
    return response.data
})

export const addInvoiceExpense = createAsyncThunk('appExpenses/addInvoiceExpense', async (expense, { dispatch }) => {
    const response = await axios.put(EXPENSES.concat('linked-cfdi/'), expense)
    await dispatch(getExpenses())
    return response.data
})

export const deleteExpense = createAsyncThunk('appExpenses/deleteExpense', async (id, { dispatch }) => {
    const response = await axios.delete(EXPENSES.concat(`${id}`))
    await dispatch(getExpenses())
    return response.data
})

export const appExpensesSlice = createSlice({
    name: 'appExpenses',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getExpenses.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default appExpensesSlice.reducer