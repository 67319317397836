import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axios from "axios"
import { SERVICES } from '../../../../../../../utility/SourceAPI'
import { formattedDatePicker, formattedDateTimePicker, minutes, seconds, miliseconds } from '../../../../../../../utility/formats/formattedDate'

const config = {
    headers: {
        'Content-Type': 'multipart/form-data;boundary=boundary'
    }
}

export const getServices = createAsyncThunk('appServices/getServices', async () => {
    const response = await axios.get(SERVICES)
    return {
        data: response.data
    }
})

export const getServicesToPayment = createAsyncThunk('appServices/getServicesToPayment', async (data) => {
    const response = await axios.get(SERVICES.concat('services_to_payment').concat(`${data?.rangeDates !== undefined ? `/?end=${formattedDatePicker(data?.rangeDates?.end)} 23:${minutes + 5}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}` : ''}`))
    return {
        data: response.data
    }
})

export const addService = createAsyncThunk('appServices/addService', async (service, { dispatch }) => {
    const response = await axios.post(SERVICES, service)
    await dispatch(getServices())
    return {
        data: response.data
    }
})

export const applyService = createAsyncThunk('appServices/applyService', async (service, { dispatch }) => {
    const id = service.get('id')
    const response = await axios.put(`${SERVICES}apply/${id}`, service, config)
    await dispatch(getServicesToPayment())
    return {
        data: response.data
    }
})

export const updateService = createAsyncThunk('appServices/updateService', async (service, { dispatch }) => {
    const response = await axios.put(`${SERVICES}${service.id}`, service)
    await dispatch(getServices())
    return {
        data: response.data
    }
})

export const updateVocuerService = createAsyncThunk('appServices/updateVocuerService', async (service, { dispatch }) => {
    const id = service.get('id')
    const response = await axios.put(`${SERVICES}update_voucher/${id}`, service, config)
    await dispatch(getServices())
    return {
        data: response.data
    }
})

export const serviceSlice = createSlice({
    name: 'appServices',
    initialState: {
        data: [],
        paymentServices: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getServices.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getServicesToPayment.fulfilled, (state, action) => {
            state.paymentServices = action.payload.data
        })
    }
})

export default serviceSlice.reducer