import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"

import {
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds, formattedDatePicker
} from '../../../../../utility/formats/formattedDate'
import { CASH_BOX } from '../../../../../utility/SourceAPI'

export const getCashBox = createAsyncThunk('appCashBox/getCashBox', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CASH_BOX.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 5}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&user_id=${data.id}`) : CASH_BOX.concat(`?user_id=${data.id}`))
    return {
        data: response.data
    }
})

export const getCashBoxUpdatable = createAsyncThunk('appCashBox/getCashBoxUpdatable', async () => {
    const response = await axios.get(CASH_BOX.concat('?is_updateable=true'))
    return {
        data: response.data
    }
})

export const addCashBox = createAsyncThunk('appCashBox/addCashBox', async (cashBox, { dispatch }) => {
    const response = await axios.post(CASH_BOX, cashBox)
    await dispatch(getCashBox())
    return response.data
})

export const updateCashBox = createAsyncThunk('appCashBox/updateCashBox', async (cashBox, { dispatch }) => {
    const response = await axios.put(CASH_BOX.concat(`${cashBox.id}`), cashBox)
    await dispatch(getCashBox())
    return response.data
})

export const uploadVoucher = createAsyncThunk('appCashBox/uploadVoucher', async (cashBox, { dispatch }) => {
    const id = cashBox.get('id')
    const response = await axios.put(CASH_BOX.concat(`update_voucher/${id}`), cashBox)
    await dispatch(getCashBox())
    return response.data
})

export const authorizeRelease = createAsyncThunk('appCashBox/authorizeRelease', async (cashBox, { dispatch }) => {
    const response = await axios.put(CASH_BOX.concat(`authorized_updateable/${cashBox}`), cashBox)
    await dispatch(getCashBoxUpdatable())
    return response.data
})


export const blockCashBox = createAsyncThunk('appCashBox/blockCashBox', async (cashBox, { dispatch }) => {
    const response = await axios.put(CASH_BOX.concat(`updateable/${cashBox}`), cashBox)
    await dispatch(getCashBox())
    return response.data
})

export const deleteCashBox = createAsyncThunk('appCashBox/deleteCashBox', async (cashBox, { dispatch }) => {
    const response = await axios.delete(CASH_BOX.concat(`${cashBox}`), cashBox)
    await dispatch(getCashBox())
    return response.data
})

export const appCashBoxSlice = createSlice({
    name: 'appCashBox',
    initialState: {
        data: [],
        updatable: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCashBox.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getCashBoxUpdatable.fulfilled, (state, action) => {
            state.updatable = action.payload.data
        })
    }
})

export default appCashBoxSlice.reducer