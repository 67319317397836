import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CFDI_TRANSACTIONS_ENDPOINT } from '../../../../../../utility/SourceAPI'
import {
    formattedDatePicker, formattedDateTimePicker, minutes,
    seconds,
    miliseconds
} from '../../../../../../utility/formats/formattedDate'


export const getTransactionsLinked = createAsyncThunk('transactions/getTransactionsLinked', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CFDI_TRANSACTIONS_ENDPOINT.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&bank_transaction_id=${data.id}`) : CFDI_TRANSACTIONS_ENDPOINT.concat(`?${data.bank_transaction_id !== null ? `bank_transaction_id=${data.bank_transaction_id}` : `${data.cash_deposit_id !== null ? `cash_deposit_id=${data?.cash_deposit_id}` : `${data?.intermediary_expense_id !== null ? `intermediary_expense_id=${data?.intermediary_expense_id}` : ''}`}`}`))
    return {
        data: response.data
    }
})

export const getTransactionsLinkedGrouped = createAsyncThunk('transactions/getTransactionsLinkedGrouped', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CFDI_TRANSACTIONS_ENDPOINT.concat(`groupByCFDI/?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : CFDI_TRANSACTIONS_ENDPOINT.concat(`groupByCFDI/`))
    return {
        data: response.data
    }
})

export const addTransactionLinked = createAsyncThunk('transactions/addTransactionLinked', async (data) => {
    const response = await axios.post(CFDI_TRANSACTIONS_ENDPOINT, data)
    return {
        data: response.data
    }
})

export const addTransactionLinkedIdentified = createAsyncThunk('transactions/addTransactionLinkedIdentified', async (data) => {
    const response = await axios.post(CFDI_TRANSACTIONS_ENDPOINT.concat('identify/'), data)
    return {
        data: response.data
    }
})

export const unlinkTransaction = createAsyncThunk('transactions/unlinkTransaction', async (data) => {
    const response = await axios.delete(CFDI_TRANSACTIONS_ENDPOINT.concat(`${data.id}`))
    return {
        data: response.data
    }
})


export const appTransactionsLinkedSlice = createSlice({
    name: 'appTransactionsLinked',
    initialState: {
        data: [],
        transactionsGrouped: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getTransactionsLinked.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getTransactionsLinkedGrouped.fulfilled, (state, action) => {
            state.transactionsGrouped = action.payload.data
        })
    }
})

export default appTransactionsLinkedSlice.reducer