import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axios from "axios"
import { CATEGORIES } from '../../../../../../../utility/SourceAPI'

export const getCategories = createAsyncThunk('appCategories/getCategories', async () => {
    const response = await axios.get(CATEGORIES)
    return {
        data: response.data
    }
})

export const addCategory = createAsyncThunk('appCategories/addCategory', async (category, { dispatch }) => {
    const response = await axios.post(CATEGORIES, category)
    await dispatch(getCategories())
    return {
        data: response.data
    }
})

export const updateCategory = createAsyncThunk('appCategories/updateCategory', async (category, { dispatch }) => {
    const response = await axios.put(CATEGORIES.concat(category.id), category)
    await dispatch(getCategories())
    return {
        data: response.data
    }
})

export const categorySlice = createSlice({
    name: 'appCategories',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default categorySlice.reducer