import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CENSUS_STATUS } from '../../../../../../utility/SourceAPI'

export const getStatusCensus = createAsyncThunk('appStatusCensus/getStatusCensus', async () => {
    const response = await axios.get(CENSUS_STATUS)
    return {
        data: response.data
    }
})

export const addStatusCensus = createAsyncThunk('appStatusCensus/addStatusCensus', async (statusCensus, { dispatch }) => {
    const response = await axios.post(CENSUS_STATUS, statusCensus)
    await dispatch(getStatusCensus())
    return {
        data: response.data
    }
})

export const updateStatusCensus = createAsyncThunk('appStatusCensus/updateStatusCensus', async (statusCensus, { dispatch }) => {
    const response = await axios.put(CENSUS_STATUS.concat(statusCensus.id), statusCensus)
    await dispatch(getStatusCensus())
    return {
        data: response.data
    }
})

export const statusCensusSlice = createSlice({
    name: 'appStatusCensus',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getStatusCensus.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default statusCensusSlice.reducer