import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { ALL_CENSUS } from '../../../../../../utility/SourceAPI'

export const getAllCensus = createAsyncThunk('appCensus/getAllCensus', async () => {
    const response = await axios.get(ALL_CENSUS)
    return {
        data: response.data
    }
})

export const censusSlice = createSlice({
    name: 'appCensus',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAllCensus.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default censusSlice.reducer