// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import {
    BANK_ACTIVITIES_ENDPOINT,
    BANK_ACTIVITIES_ENDPOINT_UPDATE,
    CANCEL_BANK_ACTIVITIES_ENDPOINT,
    APPLY_BANK_ACTIVITIES,
    UPLOAD_LAYOUT_ENDPOINT
    //**DEPRECATED */
    // TAKE_BANK_ACTIVITY_ENDPOINT,
    // UNLINK_BANK_ACTIVITY_ENDPOINT,
    // UPLOAD_TRANSFER_ENDPOINT,
} from "../../../../../../utility/SourceAPI"
import {
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds,
    formattedDatePicker
} from "../../../../../../utility/formats/formattedDate"

const config = {
    headers: {
        'Content-Type': 'multipart/form-data;boundary=boundary'
    }
}

//**TODO: handle DELETE */
export const getBankActivities = createAsyncThunk('appBankActivities/getBankActivities', async (rangeDates) => {
    const response = await axios.get(rangeDates !== undefined ? BANK_ACTIVITIES_ENDPOINT.concat(`?end=${formattedDatePicker(rangeDates.end)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(rangeDates.start)}`) : BANK_ACTIVITIES_ENDPOINT)
    return {
        data: response.data
    }
})

//**TODO: handleChecked {Table Activities Banks and register} */
export const getAllBankActivities = createAsyncThunk('appAllBankActivities/getAllBankActivities', async (rangeDates) => {
    const response = await axios.get(rangeDates !== undefined ? BANK_ACTIVITIES_ENDPOINT.concat(`?end=${formattedDatePicker(rangeDates.end)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(rangeDates.start)}&include_all_departments=true`) : BANK_ACTIVITIES_ENDPOINT.concat('?include_all_departments=true'))
    return {
        data: response.data
    }
})

//**TODO: handleChecked {table register intermediaries} */
export const getBankActivitiesIntermediary = createAsyncThunk('appBankActivities/getBankActivitiesIntermediary', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? BANK_ACTIVITIES_ENDPOINT.concat(`?include_all_departments=true&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`).concat(`&intermediary_id=${data.id}`) : BANK_ACTIVITIES_ENDPOINT.concat(`?include_all_departments=true&intermediary_id=${data.id}`))
    return {
        data: response.data
    }
})

//**TODO: handleChecked {Card activities assigned } ✅ */
export const getAllBankActivitiesAssigned = createAsyncThunk('appAllBankActivities/getAllBankActivitiesAssigned', async (data) => {
    const response = await axios.get(BANK_ACTIVITIES_ENDPOINT.concat(`?taken_by_id=${data.id}&include_all_departments=true`))
    return {
        data: response.data
    }
})

//**DEPRECATED */
// //**TODO: handleChecked {Card activities applied } ✅ */
// export const getBankActivitiesApplied = createAsyncThunk('appBankActivities/getBankActivitiesApplied', async () => {
//     const date = new Date()
//     const response = await axios.get(BANK_ACTIVITIES_ENDPOINT.concat(`?end=${formattedDatePicker(date)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDatePicker(date)} 00:${minutes + 1}:${seconds}.${miliseconds}`).concat(`&status_str=applied`))
//     return {
//         data: response.data
//     }
// })

//**TODO: checked {table assign activities} ✅*/
export const getBankActivitiesPending = createAsyncThunk('appBankActivities/getBankActivitiesPending', async (rangeDates) => {
    const response = await axios.get(rangeDates !== undefined ? BANK_ACTIVITIES_ENDPOINT.concat(`?status_str=pending&include_all_departments=true`).concat(`&end=${formattedDateTimePicker(rangeDates.end)}&start=${formattedDateTimePicker(rangeDates.start)}`) : BANK_ACTIVITIES_ENDPOINT.concat('?status_str=pending&include_all_departments=true'))
    return {
        data: response.data
    }
})

//**TODO: checked {table get my activities} ✅*/
export const getMyBankActivities = createAsyncThunk('appBankActivities/getMyBankActivities', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? BANK_ACTIVITIES_ENDPOINT.concat(`?taken_by_me=true&status_str=taken&filter_date_by=assigned_at&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : BANK_ACTIVITIES_ENDPOINT.concat(`?taken_by_me=true&status_str=taken&filter_date_by=assigned_at`))
    return {
        data: response.data
    }
})

//**TODO: checked {table get my activities applied} ✅*/
export const getMyBankActivitiesApplied = createAsyncThunk('appBankActivities/getMyBankActivitiesApplied', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? BANK_ACTIVITIES_ENDPOINT.concat(`?taken_by_me=true&status_str=applied&filter_date_by=done_on&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : BANK_ACTIVITIES_ENDPOINT.concat(`?taken_by_me=true&status_str=applied&filter_date_by=done_on`))
    return {
        data: response.data
    }
})

//**TODO: checked {card activities taken, applied & total #Activities}  ✅*/
export const getActivitiesTaken = createAsyncThunk('appBankActivities/getActivitiesTaken', async (rangeDates) => {
    const response = await axios.get(rangeDates !== undefined ? BANK_ACTIVITIES_ENDPOINT.concat('taken').concat(`&end=${formattedDatePicker(rangeDates.end)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(rangeDates.start)}`) : BANK_ACTIVITIES_ENDPOINT.concat('taken'))
    return {
        data: response.data
    }
})

export const getActivitiesLayout = createAsyncThunk('appBankActivities/getActivitiesLayout', async () => {
    const response = await axios.get(BANK_ACTIVITIES_ENDPOINT.concat('downloand_layout/'), { responseType: 'blob' })
    return response.data
})

export const addLayoutSipare = createAsyncThunk('appBankActivities/addLayoutSipare', async (layout) => {
    const response = await axios.post(BANK_ACTIVITIES_ENDPOINT.concat('upload_csv/'), layout, config)
    return response.data
})

export const createMultipleActivities = createAsyncThunk('appBankActivities/createMultipleActivities', async (data) => {
    const response = await axios.post(BANK_ACTIVITIES_ENDPOINT.concat('upload_voucher_bank_activity'), data)
    return response.data
})

//**TODO: checked {add activity from movements} ✅*/
export const addBankActivity = createAsyncThunk('appBankActivities/addBankActivity', async (bankActivity, { dispatch }) => {
    const response = await axios.post(BANK_ACTIVITIES_ENDPOINT, bankActivity)
    await dispatch(getAllBankActivities())
    return response.data
})

//**TODO: checked {Update activity from register and movements} ✅*/
export const updateBankActivity = createAsyncThunk('appBankActivities/updateBankActivity', async (bankActivity, { dispatch }) => {
    const activityId = parseInt(bankActivity.get("id"))
    const response = await axios.put(BANK_ACTIVITIES_ENDPOINT_UPDATE.concat(activityId), bankActivity)
    await dispatch(getAllBankActivities())
    return response.data
})

//**TODO: checked {Assign activity from department ✅}*/
export const assignActivity = createAsyncThunk('appBankActivities/assignActivity', async (data, { dispatch }) => {
    const response = await axios.put(BANK_ACTIVITIES_ENDPOINT.concat(`assign`), data)
    await dispatch(getBankActivitiesPending())
    await dispatch(getAllBankActivities())
    await dispatch(getActivitiesTaken())
    return response.data
})

//**TODO: checked {Transfer activity from department} ✅*/
export const transferActivity = createAsyncThunk('appBankActivities/transferActivity', async (data) => {
    const response = await axios.put(BANK_ACTIVITIES_ENDPOINT.concat(`update_deparment/?department=${data.departement}`), data)
    return response.data
})

//**TODO: checked {unAssign activity table activities BANKS} ✅*/
export const dropActivities = createAsyncThunk('appBankActivities/dropActivities', async (data, { dispatch }) => {
    const response = await axios.put(BANK_ACTIVITIES_ENDPOINT.concat(`drop`), data)
    await dispatch(getActivitiesTaken())
    await dispatch(getBankActivitiesPending())
    return response.data
})


//**DEPRECATED */
// export const takeBankActivity = createAsyncThunk('appBankActivities/takeeBankActivity', async (bankActivityId, { dispatch }) => {
//     const response = await axios.put(TAKE_BANK_ACTIVITY_ENDPOINT.concat(bankActivityId))
//     await dispatch(getBankActivitiesPending())
//     await dispatch(getBankActivities())
//     await dispatch(getMyBankActivities())
//     await dispatch(getActivitiesTaken())
//     return response.data
// })

//**DEPRECATED */
// export const unlinkBankActivity = createAsyncThunk('appBankActivities/unlinkBankActivity', async (bankActivityId, { dispatch }) => {
//     const response = await axios.put(UNLINK_BANK_ACTIVITY_ENDPOINT.concat(bankActivityId))
//     await dispatch(getBankActivitiesPending())
//     await dispatch(getBankActivities())
//     await dispatch(getMyBankActivities())
//     await dispatch(getActivitiesTaken())
//     return response.data
// })

//**TODO: checked ✅*/
export const getLayoutTransfers = createAsyncThunk('appBankActivities/getLayoutTransfers', async () => {
    const response = await axios.get(UPLOAD_LAYOUT_ENDPOINT.concat('transfers/'), { responseType: 'blob' })
    return response.data
})
//**TODO: checked ✅*/
export const uploadLayout = createAsyncThunk('appBankActivities/uploadLayout', async (layout) => {
    const response = await axios.post(UPLOAD_LAYOUT_ENDPOINT.concat('transfers/'), layout, config)
    return response.data
})

//**TODO: checked {delete activity from register and movements} ✅ */
export const cancelBankActivity = createAsyncThunk('appBankActivities/cancelBankActivity', async (bankActivityId, { dispatch }) => {
    const response = await axios.put(CANCEL_BANK_ACTIVITIES_ENDPOINT.concat(bankActivityId))
    await dispatch(getAllBankActivities())
    return response.data
})

//**TODO: checked {complete activity assigned to me} ✅*/
export const aplyBankActivity = createAsyncThunk('appBankActivities/applyBankActivity', async (bankActivity) => {
    const activityId = parseInt(bankActivity.get("id"))
    const response = await axios.put(APPLY_BANK_ACTIVITIES.concat(activityId), bankActivity, config)
    return response.data
})

//**TODO: checked {change account from myActivities} ✅ */
export const updateBankAccount = createAsyncThunk('appBankActivities/updateBankAccount', async (bankAccount) => {
    const activityId = parseInt(bankAccount.get("id"))
    const response = await axios.put(BANK_ACTIVITIES_ENDPOINT.concat(`edit/${activityId}`), bankAccount, config)
    return response.data
})

export const appBankActivitiesSlice = createSlice({
    name: 'appBankActivities',
    initialState: {
        data: [],
        activitiesPending: [],
        allBankActivities: [],
        myActivities: [],
        myActivitiesApplied: [],
        activitiesTaken: [],
        activitiesIntermediary: []
        //**DEPRECATED */
        // activitiesByUser: [],
        // activitiesAppliedByUser: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getBankActivities.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getAllBankActivities.fulfilled, (state, action) => {
            state.allBankActivities = action.payload.data
        })
        builder.addCase(getBankActivitiesPending.fulfilled, (state, action) => {
            state.activitiesPending = action.payload.data
        })
        builder.addCase(getMyBankActivities.fulfilled, (state, action) => {
            state.myActivities = action.payload.data
        })
        builder.addCase(getMyBankActivitiesApplied.fulfilled, (state, action) => {
            state.myActivitiesApplied = action.payload.data
        })
        builder.addCase(getActivitiesTaken.fulfilled, (state, action) => {
            state.activitiesTaken = action.payload.data
        })

        builder.addCase(getBankActivitiesIntermediary.fulfilled, (state, action) => {
            state.activitiesIntermediary = action.payload.data
        })

        //**DEPRECATED */
        //**TODO: include all departments */
        // builder.addCase(getAllBankActivitiesAssigned.fulfilled, (state, action) => {
        //     state.activitiesByUser = action.payload.data
        // })
        // builder.addCase(getBankActivitiesApplied.fulfilled, (state, action) => {
        //     state.activitiesAppliedByUser = action.payload.data
        // })
    }
}
)

export default appBankActivitiesSlice.reducer