
const API_URL = process.env.REACT_APP_API_URL
// GET
export const LOGIN_ENDPOINT = API_URL.concat('/auth/login/')
export const TWO_FACTOR_AUTHENTICATION = API_URL.concat('/secondary_check/')
export const CASHBOXES_ENDPOINT = API_URL.concat('/cashbox/')
export const CASHBACKS_ENDPOINT = API_URL.concat('/cashback/')
export const EXPENSES_ENDPOINT = API_URL.concat('/expenses/')
export const EXPENSE_CONCEPTS_ENDPOINT = API_URL.concat('/expense-concepts/')
export const TRANSACTIONS_ENDPOINT = API_URL.concat('/transactions/')
// Companies
export const COMPANIES_ENDPOINT = API_URL.concat('/companies/')
export const COMPANIES_STATUS = API_URL.concat('/status_company/')
export const UPDATE_COMPANIES = COMPANIES_ENDPOINT.concat('rfc/')
export const UPDATE_COMPANIES_LABEL = COMPANIES_ENDPOINT.concat('label/')
export const COMPANY_ACCOUNTS = COMPANIES_ENDPOINT.concat('accounts/')
export const ALL_CENSUS = API_URL.concat('/company_dependencies/')
export const COMPANY_CENSUS = API_URL.concat('/dependencies/')
export const CENSUS_STATUS = API_URL.concat('/status_dependencies/')

// Bank Tpes
export const BANK_TYPES_ENDPOINT = API_URL.concat('/bank-types/')
//USERS
export const USERS_ENDPOINT = API_URL.concat("/users/")
export const UPDATE_USERS_ENDPOINT = USERS_ENDPOINT.concat("id/")
export const UPDATE_USERS_PASS_ENDPOINT = API_URL.concat("/users/password-update/id/")
export const ASSIGN_CASH_BOX = API_URL.concat("/users/assignment_cash/")
// Roles
export const ROLES_ENDPOINT = API_URL.concat("/user-roles/")
export const ABILITIES_ENDPOINT = API_URL.concat("/user-abilities/")
// DEVOLUTIONS
export const DEVOLUTIONS = API_URL.concat("/intermediary-devolutions/")
// Credits
export const CREDITS_ENDPOINT = API_URL.concat("/credits/")
//Expenses
export const EXPENSES = API_URL.concat("/intermediary-expenses/")
//Commissions
export const COMMISSIONS_ENDPOINT = API_URL.concat("/intermediary-commissions/")
//Bank Accounts
export const BANK_ACCOUNTS_ENDPOINT = API_URL.concat("/bank-accounts/")
export const BANK_ACCOUNT_CHECKBOOKS_ENDPOINT = BANK_ACCOUNTS_ENDPOINT.concat("checkbook")
export const BANK_ACCOUNT_CHECKBOOK_ENDPOINT = BANK_ACCOUNTS_ENDPOINT.concat("checkbooks")
export const BANK_ACCOUNTS_ENDPOINT_ID = BANK_ACCOUNTS_ENDPOINT.concat("id/")
export const UPDATE_BANK_ACCOUNTS_ENDPOINT = BANK_ACCOUNTS_ENDPOINT.concat("id/")
export const UPDATE_BANK_ACCOUNTS_STATUS_ENDPOINT = BANK_ACCOUNTS_ENDPOINT.concat("status/")
// Transactions
export const BANK_TRANSACTIONS_ENDPOINT = API_URL.concat("/bank-transactions/")
export const GET_VOUCHER = BANK_TRANSACTIONS_ENDPOINT.concat("get-voucher/")
export const GET_VOUCHER_LINKED = BANK_TRANSACTIONS_ENDPOINT.concat("get-voucher-linked/")

export const BANK_TRANSACTIONS_ENDPOINT_MOVEMENT_TYPES = BANK_TRANSACTIONS_ENDPOINT.concat("movement-types/")

export const BANK_TRANSACTIONS_UNIDENTIFIED = BANK_TRANSACTIONS_ENDPOINT.concat("unidentified")
//CASH
export const CASH = API_URL.concat("/cash-activities/")

export const CASH_DEPOSIT = API_URL.concat("/cash-deposits/")

//CONFIRMATIONS
export const CONFIRMATIONS = API_URL.concat("/bank-confirmations/")

export const BANK_TRANSACTIONS_IDENTIFY = BANK_TRANSACTIONS_ENDPOINT.concat('identify/')
export const BANK_TRANSACTIONS_CHANGE = BANK_TRANSACTIONS_ENDPOINT.concat('transfer/')
export const BANK_TRANSACTIONS_CHANGE_TYPE = BANK_TRANSACTIONS_ENDPOINT.concat('change_movement_type/')
export const BANK_TRANSACTIONS_CANCEL_IDENTIFY = BANK_TRANSACTIONS_ENDPOINT.concat('cancel-identify/')
//Bank Activities
export const BANK_ACTIVITIES_ENDPOINT = API_URL.concat("/bank-activities/")
export const BANK_ACTIVITIES_ENDPOINT_UPDATE = BANK_ACTIVITIES_ENDPOINT.concat("update/")

// export const TAKE_BANK_ACTIVITY_ENDPOINT = BANK_ACTIVITIES_ENDPOINT.concat("take/")
// export const UNLINK_BANK_ACTIVITY_ENDPOINT = BANK_ACTIVITIES_ENDPOINT.concat("unlink/")

export const CANCEL_BANK_ACTIVITIES_ENDPOINT = BANK_ACTIVITIES_ENDPOINT.concat("cancel/")
export const APPLY_BANK_ACTIVITIES = BANK_ACTIVITIES_ENDPOINT.concat("apply/")
//UPLOAD TRANSFER
export const UPLOAD_LAYOUT_ENDPOINT = API_URL.concat("/upload-layout/")

// Checkbooks
export const CHECKBOOKS_ENDPOINT = API_URL.concat("/checkbooks/")
export const CHECKS_ENDPOINT = API_URL.concat("/checks/")
export const BANK_CHECKS_ENDPOINT = API_URL.concat("/bank-checks/")
// Register Checks
export const REGISTER_CHECKS_ENDPOINT = API_URL.concat("/register-bank-checks/")
// CHEKCS
export const CHECKS = API_URL.concat("/checks/")
export const CHECKS_REMAINING = CHECKS.concat("remaining-folios/")

//Intermediaries
export const INTERMEDIARIES_ENDPOINT = API_URL.concat("/intermediaries/")
export const SEARCH_INTERMEDIARIES_ENDPOINT = API_URL.concat("/intermediaries/search/?intermediary_id=")
export const UPDATE_INTERMEDIARIES_ENDPOINT = API_URL.concat("/intermediaries/id/")

//Intermediary Income
export const INTERMEDIARY_INCOME_ENDPOINT = API_URL.concat("/intermediary-income/")

//Transfer between Intermediaries
export const TRANSFER_INTERMEDIARY_ENDPOINT = API_URL.concat('/transference/')

// Partners
export const PARTNERS_ENDPOINT = API_URL.concat('/partners/')
export const UPDATE_PARTNER = PARTNERS_ENDPOINT.concat('id/')
// Address
export const ADDRESS_ENDPOINT = API_URL.concat('/addresses/')
export const UPDATE_ADDRESS = ADDRESS_ENDPOINT.concat('id/')
// Giros
export const GIROS_ENDPOINT = API_URL.concat('/giros/')
export const UPDATE_GIRO = GIROS_ENDPOINT.concat('id/')
// Business activities
export const BUSINESS_ACTIVITIES_ENDPOINT = API_URL.concat('/business-activities/')
export const UPDATE_ACTIVITY = BUSINESS_ACTIVITIES_ENDPOINT.concat('id/')
// Regimen fiscal
export const REGIMEN_FISCAL_ENDPOINT = API_URL.concat('/regimen-fiscal/')
// POST
export const NEW_CASHBACK_PENDING = CASHBACKS_ENDPOINT.concat('new/')
export const NEW_TRANSACTION = TRANSACTIONS_ENDPOINT.concat('new/')
export const NEW_TRANSFER = TRANSACTIONS_ENDPOINT.concat('transfer/?source_id=')
export const NEW_EXPENSE = EXPENSES_ENDPOINT.concat('new/')

// PUT
export const UPDATE_CASHBACK_PENDING = CASHBACKS_ENDPOINT.concat('id/')

// PATCH
export const CANCEL_CASHBACK = CASHBACKS_ENDPOINT.concat('cancel/id/')
export const CANCEL_EXPENSE = EXPENSES_ENDPOINT.concat('id/')

//CFDI
export const CFDI_ENDPOINT = API_URL.concat('/cfdi/')
export const CFDI_READ_XML_ENDPOINT = CFDI_ENDPOINT.concat('readxml')

//CFDI - TRANSACTIONS
export const CFDI_TRANSACTIONS_ENDPOINT = API_URL.concat('/cfdi_transaction/')

//Clients
export const CLIENTS_ENDPOINT = API_URL.concat('/clientes/')

// DELETE
export const DELETE_CASHBACK_PENDING = CASHBACKS_ENDPOINT.concat('delete/id/')

//Expenses
export const CASH_EXPENSES = API_URL.concat('/expense/')
export const CASH_EXPENSES_PREVIEW = API_URL.concat('/expenses_preview/')

//Cash Credits
export const CASH_CREDITS = API_URL.concat('/cash_credit/')

//Commissions
export const COMMISSIONS = API_URL.concat('/monthly_payments/')

//BLOCKS
export const CASH_COMMISSIONS_BLOCKS = API_URL.concat('/blocks/')
export const CASH_COMMISSIONS_BLOCKS_BODY = API_URL.concat('/body_block/')

//EXPENE TO BLOCK
export const EXPENSE_TO_BLOCK = API_URL.concat('/expense_to_block/')

//Concept
export const CONCEPT = API_URL.concat('/concept/')

//Locations
export const LOCATIONS = API_URL.concat('/location/')

//Services
export const CATEGORIES = API_URL.concat('/categories/')
export const SERVICES = API_URL.concat('/services/')
export const PAYMENT_SERVICES = API_URL.concat('/payments_services/')

//utility
export const UTILITY = API_URL.concat('/utility/')
//Receipts Generator
export const RECEIPTS_GENERATOR = API_URL.concat('/receipt-generator/')

//Catalogs Users
export const CATALOGS_USERS = API_URL.concat('/user_activity/')

export const CASH_BOX = API_URL.concat('/cash_box/')

export const GENERAL_DETAILS = API_URL.concat('/general/')